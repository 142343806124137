import React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Button from 'components/Base/Button'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent from 'components/SectionContent'
import PageBottomCard from 'components/PageBottomCard'
import SolutionsSwiper from 'components/SolutionsSwiper'
import Scheme from 'components/Scheme'
import MediaCard from 'components/MediaCard'
import DemoCardList from 'components/DemoCardList'
import MaterialCard, { MaterialCardGroup } from 'components/MaterialCard'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import { isPc } from 'utils/utils'
import * as styles from './index.module.less'

const Bank: React.FC = (props) => {
  return (
    <Layout {...props}>
      <BankState />
    </Layout>
  )
}

interface BankStateProps {
  className?: string
}
export const BankState: React.FC<BankStateProps> = (props) => {
  return (
    <>
      <Seo
        title="银行数字化运营解决方案｜神策数据 - 神策数据解决方案"
        description="帮助银行构建数字化运营体系，为银行提供全渠道、全链路的经营数据分析链路，打通数据和运营闭环、进行差异化分层的营销与服务行动；完善ID-mapping体系，丰富客户的数据资产，打造长效、核心竞争力"
        keywords="银行数字化解决方案,银行客户行为数据分析,银行一站式营销管理,银行数字资产管理,神策数据"
        saTitle="首页-产品（行业解决方案）- 银行"
      />
      <main className={styles.Bank}>
        <SolutionsBanner
          className="banner-wrap"
          title="银行业解决方案"
          subTitle="构建以客户为中心的数字化运营工作流，打造核⼼竞争力"
          desc="夯实客户数据根基，构建 360° 客户全景视图，以实时、高效、深度的数据洞察推动运营策略迭代与敏捷转型，助力银行业实现数据驱动闭环，释放生产力。"
          imgUrl={
            <img
              src={require('assets/images/solution/bank/icon-banner.png').default}
              alt="banner"
              className="banner-icon"
            />
          }
          buttonGroup={
            <Button href="/BankRetailSa" target="_blank">
              体验行业 Demo
            </Button>
          }
        />
        <SectionContent className="bank-solutions-wrap" title="银行业数字化运营解决方案">
          <SolutionsSwiper
            className="bank-solutions-swiper"
            dataSource={[
              {
                className: 'bank-01',
                icon: 'bank-01',
                tabText: (
                  <>
                    客户
                    <br className="hidden-pc" />
                    数据分析驱动
                  </>
                ),
                title: '实时、自助式客户行为分析，精准定位差异化需求',
                imgUrl: require('assets/images/solution/bank/s-01.png').default,
                descList: [
                  {
                    title: '构建数字化运营分析体系',
                    list: [
                      '构建全渠道、全链路、全方位的数据分析体系，以实时、自助式的客户行为数据分析，精准洞察客户需求',
                    ],
                  },
                  {
                    title: '量化评估并提升客户体验',
                    list: ['客观全面的线上产品服务监控、评估与诊断，打造卓越的客户体验'],
                  },
                  {
                    title: '深度赋能客群精细化经营',
                    list: ['识别客群差异化需求、关键经营时点及核心经营方向，实现全生命周期管理，推动客户资产达标'],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/BankRetailSa',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'bank-02',
                icon: 'bank-02',
                tabText: (
                  <>
                    一站式
                    <br className="hidden-pc" />
                    营销策略管理
                  </>
                ),
                title: '重塑营销工作流，达成客群经营的降本增效',
                imgUrl: require('assets/images/solution/bank/s-02.png').default,
                descList: [
                  {
                    title: '策略要素一站式整合',
                    list: ['客户数据、触达渠道、营销内容一站式对接，提升技术资源利用率，降低团队沟通成本'],
                  },
                  {
                    title: '数据化营销闭环打造',
                    list: [
                      '基于体系化数据，制定精准营销决策，实时回收数据反馈总结规律经验，沉淀营销策略库，加速策略体系化迭代',
                    ],
                  },
                  {
                    title: '精准营销提升 ROI',
                    list: ['差异化分层营销与服务，提升 ROI 的同时，消除对客户的无效打扰'],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/BankRetailSa',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'bank-03',
                icon: 'bank-03',
                tabText: (
                  <>
                    全触点
                    <br className="hidden-pc" />
                    数据资产沉淀
                  </>
                ),
                title: '实现精细、融合的客户全触点数据资产管理',
                imgUrl: require('assets/images/solution/bank/s-03.png').default,
                descList: [
                  {
                    title: '全触点数据打通',
                    list: ['基于完善的 ID-Mapping 体系与跨端、跨业务线行为数据采集，打通客户全触点数据资产'],
                  },
                  {
                    title: '全场景数据管理',
                    list: [
                      '精细的元数据管理、数据质量管理、数据权限管理，适配大型复杂机构的数据管理和应用场景，确保质量和准确性',
                    ],
                  },
                  {
                    title: '灵活、开放的系统架构',
                    list: ['支持多端、多源的数据导入、实时订阅与结果导出，释放更多数据价值'],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/BankRetailSa',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
            ]}
          />
        </SectionContent>
        <SectionContent className="media-wrap">
          <MediaCard
            autoplay={
              isPc()
                ? {
                    delay: 5000,
                  }
                : false
            }
            dataSource={[
              {
                className: 'media-card-01',
                title: '公开线上课：突破“十面埋伏”，银行业如何玩转数字化运营',
                desc: '数字化运营的落地常面临着人才、方法论、跨部门协作等多重困局。银行业该如何突破“十面埋伏”，提升运营效率，优化用户体验？',
                thumb: require('assets/images/solution/bank/shimianmaifu.png').default,
                thumbAlt: '公开线上课：突破“十面埋伏”，银行业如何玩转数字化运营',
                buttonText: '立即观看',
                buttonHref: 'https://www.sensorsdata.cn/school/library/be339101a8db375eb71872c0ad69281b',
              },
              {
                className: 'owner-app',
                title: '《金融新基建系列报告：银行业六大中期趋势展望》',
                desc: '基于持续的行业观察，神策数据推出《金融新基建系列报告：银行业六大中期趋势展望》，与银行从业者共同把握新基建背景下，可预见的几大关键趋势。',
                thumbClassName: 'media-2020s',
                thumb: require('assets/images/solution/bank/yhyldzqqszw.png').default,
                thumbAlt: '《金融新基建系列报告：银行业六大中期趋势展望》',
                buttonText: '免费下载',
                buttonHref: 'https://www.sensorsdata.cn/school/library/e4e866b5a3fc7a380c286524cf0aaeb0',
              },
              {
                className: 'owner-app',
                title: '《面向 2020s：银行数字营销现状洞察》',
                desc: '神策数据联合中国电子银行网，共同发布《面向 2020s：银行数字营销现状洞察》，帮助从业者理解银行 4.0 时代，数据化营销闭环在银行业数字营销中的价值。',
                thumbClassName: 'media-2020s',
                thumb: require('assets/images/solution/bank/2020s.png').default,
                thumbAlt: '《面向 2020s：银行数字营销现状洞察》',
                buttonText: '免费下载',
                buttonHref: 'https://www.sensorsdata.cn/school/library/f831091c698fe8767bc54276b809139a',
              },
            ]}
          />
        </SectionContent>
        <SectionContent className="three-scan" title="赋能银行零售、信用卡、对公业务三大场景" backgroundColor="#F8FBFD">
          <DemoCardList
            className="demo-card-list"
            dataSource={[
              {
                iconUrl: require('assets/images/solution/bank/d-01.png').default,
                title: '银行零售 Demo',
                descList: [
                  '流量分发效果监测，提升资源位运营效率',
                  '客户体验深度诊断，优化产品设计',
                  '业务断点识别及客群洞察，支持精准营销',
                ],
                buttonList: [
                  {
                    text: '点击体验',
                    href: '/BankRetailSa',
                  },
                ],
              },
              {
                iconUrl: require('assets/images/solution/bank/d-02.png').default,
                title: '信用卡 Demo',
                descList: [
                  '网申进件渠道追踪，赋能推广投放',
                  '用卡场景深度分析，促进线上迁移与业务转化',
                  '活动效果评估，打造营销闭环',
                ],
                buttonList: [
                  {
                    text: '点击体验',
                    href: '/BankCreditCardSa',
                  },
                ],
              },
              {
                iconUrl: require('assets/images/solution/bank/d-03.png').default,
                title: '对公业务 Demo',
                descList: [
                  '操作员级行为明细监测，提供主动陪伴式服务',
                  '系统可用性、易用性量化分析，促进产品迭代',
                  '企业健康度画像洞察，助力续约达成',
                ],
                buttonList: [
                  {
                    text: '点击体验',
                    href: '/BankCreditCardSa',
                  },
                ],
              },
            ]}
          />
        </SectionContent>
        <SectionContent className="framework" title="以客户为中心的解决方案架构图">
          <div className="framework-content">
            <img
              src={require('assets/images/solution/bank/framework.png').default}
              className="framework"
              alt="以客户为中心的解决方案架构图"
            />
          </div>
          <div className="sd-button-group">
            <Button btnType="primary" href="/BankRetailSa" target="_blank">
              体验行业 Demo
            </Button>
            <a className="linkBtn" target="_blank" href="https://www.sensorsdata.cn/democenter/bank.html">
              <span className="textBtn">场景解读</span>
            </a>
          </div>
        </SectionContent>
        <SectionContent className="scheme-wrap" title="为什么选择神策？" backgroundColor="#F8FBFD">
          <Scheme
            columnNum={5}
            useSwiper={!isPc()}
            itemHeight={200}
            dataSource={[
              {
                title: '行业经验沉淀',
                desc: '30+ 家银行客户服务案例，100% 私有化部署，银行业全场景指标体系、采集方案及营销策略沉淀',
                icon: require('assets/images/solution/bank/t-05.png').default,
                alt: '行业经验沉淀',
              },
              {
                title: '可视化操作管理',
                desc: '可视化数据分析及营销策略配置，自助式看板、标签、策略管理，提升团队效率',
                icon: require('assets/images/solution/bank/t-03.png').default,
                alt: '可视化操作管理',
              },
              {
                title: '实时营销触达',
                desc: '把握关键经营时点，通过自由组合、自动执行的行为触发（Event-Triggered）营销提升客户价值',
                icon: require('assets/images/solution/bank/t-04.png').default,
                alt: '实时营销触达',
              },
              {
                title: '开放 PaaS 平台',
                desc: '开放的系统级数据导入、导出能力，支撑二次开发，实现数据流与业务流的全链路融合',
                icon: require('assets/images/solution/bank/t-01.png').default,
                alt: '开放 PaaS 平台',
              },
              {
                title: '数据质量保证',
                desc: '可靠的数据采集技术与跨端数据整合能力，数百人产研团队近十年研发迭代，产品成熟度高，性能稳定',
                icon: require('assets/images/solution/bank/t-02.png').default,
                alt: '数据质量保证',
              },
            ]}
          />
          <div className="sd-button-group">
            <ScanCodeConsultButton
              buttonText="扫码咨询"
              qrcode={require('assets/images/solution/game/panorama_code.png').default}
            />
          </div>
        </SectionContent>
        <SectionContent
          className="customer"
          title="我们服务的银行业客户"
          hideTitleUnderline={true}
          showHeaderLine={true}
          showFooterLine={true}
          titleIcon={require('assets/images/solution/bank/icon-bank.png').default}
        >
          <p className="desc">
            神策数据已经服务 50 余家银行客户，TOP50 银行覆盖率超过 40%，其中 5 家国有大行，7 家全国性股份制银行，近 30
            家城农商行等。 一直以来，神策数据致力稳健推动银行数字化转型，实现金融安全性与合规性。
          </p>
          <ul className="desc desc-list">
            <li>2019 年神策数据斩获中国电子银行网“2019 银行业数字营销大赛”（企业组）金奖</li>
            <li>2020 年神策数据荣登“2020 IDC 中国 Fintech 50 强榜单”</li>
            <li>
              2021 年深圳农商银行、昆山农商银行的服务案例获“2021
              中国金融数字科技创新大赛”之“综合智能平台类”金奖、“数字营销创新奖”
            </li>
            <li>
              2022
              年初，「北京银行行为数据分析系统」「廊坊银行渠道智能分析平台」入选中国信息通信研究院等组织的2021年大数据星河（Galaxy）案例之“行业大数据应用优秀案例”
            </li>
            <li>
              2022 年 6
              月，北京银行、深圳农商银行通过了中国信息通信研究院《企业数据应用能力成熟度模型：数字营销》的首批企业数字营销能力评测
            </li>
          </ul>
          <img
            className="customer-logo-group"
            src={require('assets/images/solution/bank/bank-logo.png').default}
            alt=""
          />
          <img
            className="customer-logo-group-mb"
            src={require('assets/images/solution/bank/bank-logo-mb.png').default}
            alt=""
          />
        </SectionContent>
        <SectionContent className={styles.highlights} title="更多精彩">
          <MaterialCardGroup className={styles.materialGroup}>
            {[
              {
                imgSrc: require('assets/images/solution/bank/zh/m-01.jpeg').default,
                title: '银行数字化运营体系构建的下半场',
                href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=4142',
                buttonText: '前往观看视频',
              },
              {
                imgSrc: require('assets/images/solution/bank/zh/m-02.jpeg').default,
                title: '客户体验管理新势力：定性与定量并重，构建全渠道实时监测与改进闭环',
                href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=4145',
                buttonText: '前往观看视频',
              },
              {
                imgSrc: require('assets/images/solution/bank/zh/m-03.jpeg').default,
                title: '如何利用数据“武器”提升线上金融服务能力？',
                href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3094',
                buttonText: '前往观看视频',
              },
              {
                imgSrc: require('assets/images/solution/bank/zh/m-04.jpeg').default,
                title: '突破“十面埋伏”，银行业如何玩转数字化运营',
                href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=2886',
                buttonText: '前往观看视频',
              },
            ]?.map((item) => (
              <MaterialCard key={item.title} {...item} />
            ))}
          </MaterialCardGroup>
        </SectionContent>
        <PageBottomCard
          title="打造银行 4.0 时代的数字化运营分析平台！"
          desc="立即预约，与神策银行业专家一起挖掘数据中的增长机会！"
          leftButtonText="体验行业 Demo"
          leftButtonHref="/BankRetailSa"
          rightButtonText="预约上门演示"
          rightButtonHref="https://www.sensorsdata.cn/form/parade.html"
        />
      </main>
    </>
  )
}

export default Bank
